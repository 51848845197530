@keyframes highlightFade {
  from {
    background-color: red;
  }

  to {
    background-color: transparent;
  }
}

.highlight-row {
  animation: highlightFade 1s forwards;
  /* 动画持续时间与 useEffect 中的计时器一致 */
}